<template>
  <div
    class="flex items-center mt-1.5 py-0.4 px-1 bg-orange rounded"
    :class="mode !== 'edit-started' ? 'hidden' : ''"
  >
    <img src="@/assets/img/icons/info-white.svg" class="mr-1" />
    <p class="font-medium text-white text-sm">
      Any changes you make to the setup may affect the participants that have
      already started taking the assessment.
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "InfoBanner",
  computed: {
    ...mapState({ mode: (state) => state.assessmentSetup.mode }),
  },
};
</script>
