<template>
  <div>
    <div class="flex flex-grow justify-between items-end mb-2">
      <p class="text-sm-2 text-grey-dark-2">
        Showing {{ mappedList.length }} communication records
      </p>
      <InputField
        placeholder="Email address"
        label="Filter by"
        :onChange="setKeyword"
        :propValue="keyword"
        leftIcon
        class="w-inputField"
        data-testid="filter-field"
      />
    </div>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <div class="flex flex-grow w-1/6  min-w-150">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('date', 0)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">Date</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5  min-w-200">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('email', 1)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">Email</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/3">
          <div class="flex flex-grow">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sort('subject', 2)"
              data-testid="header-2"
            >
            <p class="text-sm-2 text-grey-light mr-0.4">Subject</p>
            <img
            src="../../../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
          />
          </button>
          </div>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 364px; overflow-y: auto;"
          :data-key="'index'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          data-testid="participants-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapState, mapActions } from "vuex";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import InputField from "../../../../components/InputField/InputField.vue";
import Button from "../../../../components/Button/Button.vue";
import AddNewButton from "../AddNewButton.vue";

export default {
  name: "EmailActivityLogTable",
  components: {VirtualList, InputField, Button, AddNewButton },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    selectAll: false,
  }),
  computed: {
    ...mapState({
      mode: (state) => state.assessmentSetup.mode,
      allActivityLogs: (state) => state.assessmentSetup.communicationActivityLog,
      id: (state) => state.assessmentSetup.cloneId,
    }),
  },
  watch: {
    keyword() {
      this.updateList();
    },
    allActivityLogs() {
      this.mappedList = this.allActivityLogs.map((x, index) => ({...x, index}));
    }
  },
  async mounted() {
    await this.updateList();
  },
  methods: {
    ...mapActions({
      getCommunicationActivityLog: 'assessmentSetup/getCommunicationActivityLog',
    }),
    getActivityLog() {
      return this.getCommunicationActivityLog({
        id: this.id,
        keyword: this.keyword,
      });
    },
    async updateList() {
      await this.getActivityLog();
    },
    sort(key, index) {
      this.setRotation(index);
      switch (key) {
        case "date":
          this.mappedList = [...this.mappedList].sort((a, b) =>  
            this.rotationMatrix[index] * ((new Date(a.date).valueOf()) - (new Date(b.date).valueOf())));
          break;
        case "email":
        this.mappedList = [...this.mappedList].sort((a, b) => this.rotationMatrix[index] * 
              (a.email.localeCompare(b.email)));
          break;
        case "subject":
          this.mappedList = [...this.mappedList].sort((a, b) => this.rotationMatrix[index] * 
            (a.subject.toLowerCase().localeCompare(b.subject.toLowerCase())));
          break;
        default:
          break;
      }
    },
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
  },
};
</script>
