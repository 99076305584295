<template>
  <div :data-testid="`listitem-${index}`">
    <div class="flex flex-row w-full items-center px-2 h-50 bg-white">
      <div class="flex flex-grow w-1/6 min-w-150">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.date }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5  min-w-200">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.email }}
        </p>
      </div>
      <div class="flex flex-grow w-1/3">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.subject }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'ListItem',
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
  },
};
</script>
