<template>
  <div>
    <div class="flex mb-2.5">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /<span
          class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
          Communication Activity
        </span>
      </p>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-2 pb-4.5 relative">
      <div class="max-w-4/5">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
          Email Notifications Activity Log
        </p>
        <p class="text-mdh text-grey-dark-1 mb-2">
          This is a centralised list for tracking every email communication sent, the sent date, email of the recipient
          and corresponding subject.
        </p>
      </div>
      <EmailActivityLogTable />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import Button from '../../../components/Button/Button.vue';
import InputField from '../../../components/InputField/InputField.vue';
import NewSelect from '../../../components/Select/NewSelect.vue';
import CommunicationBanner from '../components/CommunicationBanner.vue';
import InfoBanner from '../components/InfoBanner.vue';
import EmailActivityLogTable from '../components/EmailActivityLogTable';

export default {
  name: 'CommunicationActivity',
  components: {
    Button,
    InputField,
    NewSelect,
    CommunicationBanner,
    InfoBanner,
    EmailActivityLogTable,
  },
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
    }),
  },
  methods: {
    goBack() {
      this.$router.push('/assessments/settings');
    },
    displayVersionForSeller(version) {
      if (version && version !== "" && (this.isImpersonating || this.isSeller))
        return " (v" + version + ")";
      return "";
    }
  },
  beforeRouteEnter(to, _, next) {
    const title = store.getters['assessmentSetup/getAssessmentTitle'];
    to.meta.sidenav.title = title + ' Setup';
    next();
  },
};
</script>
