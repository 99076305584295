<template>
  <div
    class="flex items-center mt-1.5 py-0.4 px-1 bg-blue-dark-1 rounded"
    :class="mode !== 'edit-launched' ? 'hidden' : ''"
  >
    <img src="@/assets/img/icons/info-white.svg" class="mr-1" />
    <p class="font-medium text-white text-sm">
      Please make sure to check if the communication messages are still accurate
      (e.g. in case the close date has been changed).
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CommunicationBanner",
  computed: {
    ...mapState({ mode: (state) => state.assessmentSetup.mode }),
  },
};
</script>
